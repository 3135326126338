import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/components/base-component';

@Directive()
export abstract class BaseButton extends BaseComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ HOST BINDINGS
    // -----------------------------------------------------------------------------------------------------

    @HostBinding('class') class: string;

    @HostBinding('style.display') display: string = 'block';

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() text: string;

    @Input() testId: string;
}

@Directive()
export abstract class BaseRouterButton extends BaseButton {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() routerLink: string;
}

@Directive()
export abstract class BaseActionButton extends BaseButton {

    // -----------------------------------------------------------------------------------------------------
    // @ OUTPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() colorClass: string = 'default';

    @Input() textClass: 'text-default' | 'text-default--bold' | 'text-blue' | 'text-red' | 'text-white' | 'text-green' = 'text-default';

    @Input() sizeClass: 'small' | 'medium' | 'large' | 'inherit' = 'large';

    @Input() buttonStyle: string = '';

    @Input() icon: string = '';

    @Input() iconClass: string = '';

    @Input() order: 'icon-first' | 'icon-last' = 'icon-first';

    @Input() tooltipText: string;

    @Input() tooltipDisabled: boolean = true;

    @Input() isDisabled: boolean = false;

    @Input() displayText: string;
}

@Directive()
export abstract class BaseMenuButton extends BaseButton {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() colorClass: 'default' | 'green' | 'transparent' | 'purple' = 'default';

    @Input() sizeClass: 'small' | 'medium' | 'large' | 'inherit' = 'large';

    @Input() icon: string = '';

    @Input() iconClass: string = '';

    @Input() order: 'icon-first' | 'icon-last' = 'icon-first';

    @Input() tooltipText: string;

    @Input() tooltipDisabled: boolean = true;

    @Input() isDisabled: boolean = false;

    @Input() displayText: string;
}

@Directive()
export abstract class BaseLoadingButton extends BaseActionButton {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() isLoading: boolean = false;
}

@Directive()
export abstract class BaseSuccessButton extends BaseLoadingButton {

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() isSuccessful: boolean = false;
}