import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JadFormFieldsTextComponent } from './form-fields-text.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports: [
        CommonModule,

        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        TranslateModule,
        MatIconModule,
        MatCheckboxModule
    ],
  declarations: [JadFormFieldsTextComponent],
  exports: [JadFormFieldsTextComponent]
})
export class JadFormFieldsTextModule { }
