import { Component } from '@angular/core';

import { BaseSuccessButton } from '../shared/base-button';

@Component({
  selector: 'kb-buttons-submit',
  templateUrl: './buttons-submit.component.html',
  styleUrls: ['./buttons-submit.component.scss']
})
export class ButtonsSubmitComponent extends BaseSuccessButton {
}