import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonsSubmitComponent } from './buttons-submit.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,

    MatProgressSpinnerModule,
    MatRippleModule,
    MatTooltipModule,
    MatIconModule
  ],
  declarations: [ButtonsSubmitComponent],
  exports: [ButtonsSubmitComponent]
})
export class ButtonsSubmitModule { }
